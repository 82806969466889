import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

export default function Outersuccessbreadcrumb() {
  return (
    <div className="breadcumb-sub-page about-banner subpage-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-12 pad40">
            <div className="inner-title text-center">
              <h3 className="heading-wrapper about-breadcub-title text-center">
                {" "}
                Heartfulness and Cine Meditation{" "}
              </h3>
              <div classname="center-item">
                <Breadcrumb className="about-breadcumb">
                  <Breadcrumb.Item href="/"><span  className="home-color-offerings">Home</span></Breadcrumb.Item>
                  <Breadcrumb.Item href="/"><span  className="home-color-offerings">HOH Series</span> </Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {" "}
                    <span  className="home-color-offerings">   Heartfulness and Cine Meditation</span>
                  </Breadcrumb.Item>
                
                </Breadcrumb>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
