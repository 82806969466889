import React from "react";

import cinemeditation from "../../../assets/images/resources/cine-meditation.png";



import fbicon from "../../../assets/images/resources/facebook (1).png";

import twittericon from "../../../assets/images/resources/twitter (1).png";

import whatsappicon from "../../../assets/images/resources/whatsapp.png";

import pintersticon from "../../../assets/images/resources/pinterest.png";

import linkdinicon from "../../../assets/images/resources/linkedin.png";

import { Link } from "gatsby";

export default function Outersucesscontentpage() {
  return (
    <section className="outer-sucess-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <img
              className="img-fluid"
              src={cinemeditation}
              alt="Wellness banner"
            />

            <div class="entry-content">
              <div className="pad30">
                <h4 class="hfn-author-name text-center padd-b10">
                
                </h4>

                <div class="single-share-box">
                  <div class="share-link-description">Share this</div>
                  <div class="share-buttons">
                    <Link
                      class="facebook"
                      to="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F&amp;t=From+Inner+Awareness+to+Outer+Success"
                      title="Facebook"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={fbicon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="twitter"
                      to="https://twitter.com/share?url=https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F&amp;text=From+Inner+Awareness+to+Outer+Success"
                      title="Twitter"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={twittericon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="whatsapp"
                      to="https://api.whatsapp.com/send?text=From%20Inner%20Awareness%20to%20Outer%20Success%20-%20https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F"
                      title="WhatsApp"
                      target="_blank"
                      rel="noreferrer"
                      data-action="share/whatsapp/share"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={whatsappicon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="pinterest pinit-marklet"
                      to="//pinterest.com/pin/create/button/"
                      title="Pinterest"
                      target="_blank"
                      data-pin-config="above"
                      rel="noreferrer"
                      data-pin-do="buttonBookmark"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={pintersticon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="linkedin"
                      to="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F&amp;title=From%20Inner%20Awareness%20to%20Outer%20Success&amp;summary=&amp;source=Heartfulness%20Primary"
                      title="LinkedIn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={linkdinicon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                  <p className="p-tag-offerings">
                  Reunion is an island located in the west of Indian Ocean, east of Africa, in the southern hemisphere. It is a French department and an overseas region of France. 
                    </p>
                    <p className="p-tag-offerings">
                    
Réunion is seen as a “European” destination in the Indian Ocean, and is primarily French speaking. It is unique and, offer a glimpse to all who visit of the importance of diversity in both nature and humanity. 
                    </p>
                    <p className="p-tag-offerings">
                    We have 3 centres on the island and a multicultural society. The slow paced and less formal lifestyle makes the concept of ‘Cinema Meditation’ very effective as an activity for creating awareness of Heartfulness Meditation in society. 
                    </p>
                    <h2 className="theme-h4 terms">What is Ciné Meditation?</h2>
                    <p className="p-tag-offerings">
                    It’s very simple, this event takes place in 3 stages: projection of a film, a discussion or Q and A session, and ending the event with an experience of Heartfulness meditation. Ciné Méditation events are initiatives that are organized in collaboration with different cinemas. 
                    </p>
                    <p className="p-tag-offerings">It is an opportunity to broaden our vision of the world through the projection of inspiring films. We have organized Ciné meditations in several towns at different times, using uplifting documentaries focusing on wellbeing, nature and spirituality. One particular documentary presented was entitled ‘De-bout’. The director of this nationally acclaimed film then visited the Island for public events as well. He now participates in the ‘Yoga4unity events’ in French. 

                    </p>
                    <p className="p-tag-offerings">Ciné Meditation events are quite interesting to people who are interested in wellbeing, self-improvement, positive change in the world and nature. The light discussions after the film gives people the chance to share ideas and create connections. Thereafter people generally stay to participate in the meditation. </p>
                    <p className="p-tag-offerings">The Heartfulness relaxation and meditation experience allows the aware-ness and inspiration that would have emerged during the film to be deep-ened through meditation. All in all , this experience offers a new, lively and original approach to the post-film experience. Now that the Covid pandemic situation on the island has greatly improved we hope to conduct more such ciné meditation events. </p>
                  <h2 className="theme-h4 terms">Ratnam Kanjee Desriaux </h2>
                  <p className="p-tag-offerings">Reunion Island</p>
                  </div>
                </div>
              </div>

              {/* <div class="entry-meta">
                <span class="category-link">
                  Category:&nbsp;
                  <a href={() => false}>
                    HOH Series
                  </a>
                </span>
                <a
                 href={() => false}
                  title="10:22 am"
                  class="data-link"
                  rel="noreferrer"
                >
                  <time
                    class="entry-date updated"
                    datetime="2021-08-09T10:22:26+05:30"
                  >
                    August 9, 2021
                  </time>
                </a>
              </div> */}
           

              <div class="single-share-box">
                <div class="share-link-description">Share this post</div>
                <div class="share-buttons">
                  <Link
                    class="facebook"
                    to="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fheartfulness.org%2Fin%2Ffrom-inner-awareness-to-outer-success%2F&amp;t=From+Inner+Awareness+to+Outer+Success"
                    title="Facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span class="soc-font-icon">
                    <img
                          className="img-fluid"
                          src={fbicon}
                          alt="Wellness banner"
                        />
                    </span>
                  </Link>
                  <Link
                    class="twitter"
                    to="https://twitter.com/share?url=https%3A%2F%2Fheartfulness.org%2Fin%2Ffrom-inner-awareness-to-outer-success%2F&amp;text=From+Inner+Awareness+to+Outer+Success"
                    title="Twitter"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span class="soc-font-icon">
                    <img
                          className="img-fluid"
                          src={twittericon}
                          alt="Wellness banner"
                        />
                    </span>
                  </Link>
                  <Link
                    class="whatsapp"
                    to="https://api.whatsapp.com/send?text=From%20Inner%20Awareness%20to%20Outer%20Success%20-%20https%3A%2F%2Fheartfulness.org%2Fin%2Ffrom-inner-awareness-to-outer-success%2F"
                    title="WhatsApp"
                    target="_blank"
                    data-action="share/whatsapp/share"
                    rel="noreferrer"
                  >
                    <span class="soc-font-icon">
                    <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={whatsappicon}
                          alt="Wellness banner"
                        />
                      </span>
                    </span>
                  </Link>
                  <Link
                    class="pinterest pinit-marklet"
                    to="//pinterest.com/pin/create/button/"
                    title="Pinterest"
                    target="_blank"
                    data-pin-config="above"
                    data-pin-do="buttonBookmark"
                    rel="noreferrer"
                  >
                    <span class="soc-font-icon">
                    <img
                          className="img-fluid"
                          src={pintersticon}
                          alt="Wellness banner"
                        />
                    </span>
                  </Link>
                  <Link
                    class="linkedin"
                    to="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fheartfulness.org%2Fin%2Ffrom-inner-awareness-to-outer-success%2F&amp;title=From%20Inner%20Awareness%20to%20Outer%20Success&amp;summary=&amp;source=Heartfulness%20India"
                    title="LinkedIn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span class="soc-font-icon">
                    <img
                          className="img-fluid"
                          src={linkdinicon}
                          alt="Wellness banner"
                        />
                    </span>
                  </Link>
                </div>
              </div>

            
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
