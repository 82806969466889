import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Cinemeditationbreadcreumb from "../components/resources/cine-meditation/cine-meditation-breadcumb.jsx";


import CinemeditationContentpage from "../components/resources/cine-meditation/cine-meditation-content.jsx";


// markup
const Internationalday = () => {
  return (
    <div>
      <Seo title="Humans Of Heartfulness" />

      <Layout isSticky>
        <section>
          <Cinemeditationbreadcreumb />
        </section>

        <section>
          <CinemeditationContentpage/>
        </section>
      </Layout>
    </div>
  );
};

export default Internationalday;
